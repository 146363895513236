var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "callRecordCountReportContainerFlexColumn" },
    [
      _c(
        "div",
        { staticClass: "callRecordCountReportContainerFlexColumn__top" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("话务量报表统计")]),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "dc-search-panel",
        {
          staticStyle: {
            border: "none",
            "background-color": "white",
            "margin-bottom": "0",
          },
          attrs: { params: _vm.queryParams },
          on: { search: _vm.doSearch, reset: _vm.reset },
        },
        [
          _c("dc-date-picker", {
            attrs: {
              label: "统计时间(起)",
              field: "startDate",
              type: "date",
              placeholder: "选择日期",
              "value-format": "yyyy-MM-dd",
            },
          }),
          _c("dc-date-picker", {
            attrs: {
              label: "统计时间(止)",
              field: "endDate",
              type: "date",
              placeholder: "选择日期",
              "value-format": "yyyy-MM-dd",
            },
          }),
          _c(
            "dc-form-item",
            { attrs: { label: "筛选条件" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "筛选条件" },
                  model: {
                    value: _vm.queryParams.orderBy,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orderBy", $$v)
                    },
                    expression: "queryParams.orderBy",
                  },
                },
                _vm._l(_vm.selectList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "dc-form-item",
            { attrs: { label: "排序类型" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "asc" },
                  model: {
                    value: _vm.queryParams.orderType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orderType", $$v)
                    },
                    expression: "queryParams.orderType",
                  },
                },
                [_vm._v("升序")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "desc" },
                  model: {
                    value: _vm.queryParams.orderType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orderType", $$v)
                    },
                    expression: "queryParams.orderType",
                  },
                },
                [_vm._v("降序")]
              ),
            ],
            1
          ),
          _c("dc-input", { attrs: { field: "username", label: "客服名称" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.getNowDay } },
            [_vm._v("当天")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.getMonth } },
            [_vm._v("当月")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.doSearch } },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.reset } },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.exportExcel } },
            [_vm._v("导出Excel")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "callRecordCountReportContainerFlexColumn__body" },
        [
          _c(
            "dc-datatable",
            {
              ref: "list",
              attrs: {
                pagination: false,
                url: _vm.apiUrl,
                params: _vm.queryParams,
                "cell-style": _vm.colunmStyle,
                "show-summary": true,
              },
              on: { loaded: _vm.loaded },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "username",
                  width: "120",
                  label: "客服名称",
                  align: "center",
                  "class-name": "fixed-column",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  width: "120",
                  label: "客服账号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "call_total",
                  width: "70",
                  label: "总话务量",
                  align: "center",
                },
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "呼入统计",
                    align: "center",
                    "class-name": "callIn__column-header",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "call_in_count",
                      width: "80",
                      label: "话务量",
                      align: "center",
                      "class-name": "callIn__column-header",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "call_in_answer_count",
                      width: "80",
                      label: "接听数",
                      align: "center",
                      "class-name": "callIn__column-header",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "call_in_answer_percent",
                      width: "80",
                      label: "接听率",
                      align: "center",
                      "class-name": "callIn__column-header",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: `background-color:${_vm.getWaringClass(
                                  scope.row.call_in_answer_percent
                                )};padding:10px 0 !important;`,
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("getPercent")(
                                        scope.row.call_in_answer_percent
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "answer15",
                      width: "80",
                      label: "15s接听数",
                      align: "center",
                      "class-name": "callIn__column-header",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "answer15_percent",
                      width: "80",
                      label: "15s接听率",
                      align: "center",
                      "class-name": "callIn__column-header",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: `background-color:${_vm.getWaringClass(
                                  scope.row.answer15_percent
                                )};padding:10px 0 !important;`,
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("getPercent")(
                                        scope.row.answer15_percent
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "call_in_answer_lost_count",
                      width: "80",
                      label: "呼损数",
                      align: "center",
                      "class-name": "callIn__column-header",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "call_in_answer_lost_percent",
                      width: "80",
                      label: "呼损率",
                      align: "center",
                      "class-name": "callIn__column-header",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: `background-color: ${_vm.getWaringClass(
                                  scope.row.call_in_answer_lost_percent
                                )}; padding: 10px 0 !important;`,
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("getPercent")(
                                        scope.row.call_in_answer_lost_percent
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "answerseconds",
                      width: "120",
                      label: "总通话时长",
                      align: "center",
                      "class-name": "callIn__column-header",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("getTimeFormat")(
                                    scope.row.answerseconds
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "answerseconds_avg",
                      width: "120",
                      label: "平均通话时长",
                      align: "center",
                      "class-name": "callIn__column-header",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("getTimeFormat")(
                                    Math.round(scope.row.answerseconds_avg)
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "呼出统计",
                    align: "center",
                    "class-name": "callOut__column-header",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "call_out_count",
                      width: "80",
                      label: "话务量",
                      align: "center",
                      "class-name": "callOut__column-header",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "call_out_answer_count",
                      width: "80",
                      label: "接听数",
                      align: "center",
                      "class-name": "callOut__column-header",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "call_out_answer_percent",
                      width: "80",
                      label: "接听率",
                      align: "center",
                      "class-name": "callOut__column-header",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: `background-color:${_vm.getWaringClass(
                                  scope.row.call_out_answer_percent
                                )};padding:10px 0 !important;`,
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("getPercent")(
                                        scope.row.call_out_answer_percent
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "call_out_no_answer_lost_count",
                      width: "80",
                      label: "呼损数",
                      align: "center",
                      "class-name": "callOut__column-header",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "call_out_no_answer_lost_percent",
                      width: "80",
                      label: "呼损率",
                      align: "center",
                      "class-name": "callOut__column-header",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: `background-color:${_vm.getWaringClass(
                                  scope.row.call_out_no_answer_lost_percent
                                )};padding:10px 0 !important;`,
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("getPercent")(
                                        scope.row
                                          .call_out_no_answer_lost_percent
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }