<template>
  <div class="callRecordCountReportContainerFlexColumn">
    <div class="callRecordCountReportContainerFlexColumn__top">
      <div class="title">话务量报表统计</div>
      <el-divider />
    </div>
    <dc-search-panel :params="queryParams" style="border: none; background-color: white; margin-bottom: 0" @search="doSearch" @reset="reset">
      <dc-date-picker label="统计时间(起)" field="startDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></dc-date-picker>
      <dc-date-picker label="统计时间(止)" field="endDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></dc-date-picker>
      <dc-form-item label="筛选条件">
        <el-select v-model="queryParams.orderBy" placeholder="筛选条件">
          <el-option v-for="item in selectList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </dc-form-item>
      <dc-form-item label="排序类型">
        <el-radio v-model="queryParams.orderType" label="asc">升序</el-radio>
        <el-radio v-model="queryParams.orderType" label="desc">降序</el-radio>
      </dc-form-item>
      <dc-input field="username" label="客服名称"></dc-input>
    </dc-search-panel>

    <div class="buttons">
      <el-button type="primary" @click="getNowDay">当天</el-button>
      <el-button type="primary" @click="getMonth">当月</el-button>
      <el-button type="primary" @click="doSearch">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
      <el-button type="primary" @click="exportExcel">导出Excel</el-button>
    </div>

    <div class="callRecordCountReportContainerFlexColumn__body">
      <dc-datatable ref="list" :pagination="false" :url="apiUrl" :params="queryParams" :cell-style="colunmStyle" :show-summary="true" @loaded="loaded">
        <el-table-column prop="username" width="120" label="客服名称" align="center" class-name="fixed-column"></el-table-column>
        <el-table-column prop="phone" width="120" label="客服账号" align="center"></el-table-column>
        <el-table-column prop="call_total" width="70" label="总话务量" align="center"></el-table-column>
        <!--        <el-table-column prop="call_answer_total" width="120" label="总接听数" align="center"></el-table-column>-->
        <!--        <el-table-column prop="call_no_answer_total" width="120" label="总未接听数" align="center"></el-table-column>-->
        <el-table-column label="呼入统计" align="center" class-name="callIn__column-header">
          <el-table-column prop="call_in_count" width="80" label="话务量" align="center" class-name="callIn__column-header"></el-table-column>
          <el-table-column prop="call_in_answer_count" width="80" label="接听数" align="center" class-name="callIn__column-header"></el-table-column>
          <el-table-column prop="call_in_answer_percent" width="80" label="接听率" align="center" class-name="callIn__column-header">
            <template #default="scope">
              <div :style="`background-color:${getWaringClass(scope.row.call_in_answer_percent)};padding:10px 0 !important;`">
                {{ scope.row.call_in_answer_percent | getPercent }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="answer15" width="80" label="15s接听数" align="center" class-name="callIn__column-header"></el-table-column>
          <el-table-column prop="answer15_percent" width="80" label="15s接听率" align="center" class-name="callIn__column-header">
            <template #default="scope">
              <div :style="`background-color:${getWaringClass(scope.row.answer15_percent)};padding:10px 0 !important;`">
                {{ scope.row.answer15_percent | getPercent }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="call_in_answer_lost_count" width="80" label="呼损数" align="center" class-name="callIn__column-header"></el-table-column>
          <el-table-column prop="call_in_answer_lost_percent" width="80" label="呼损率" align="center" class-name="callIn__column-header">
            <template #default="scope">
              <div :style="`background-color: ${getWaringClass(scope.row.call_in_answer_lost_percent)}; padding: 10px 0 !important;`">
                {{ scope.row.call_in_answer_lost_percent | getPercent }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="answerseconds" width="120" label="总通话时长" align="center" class-name="callIn__column-header">
            <template #default="scope">
              {{ scope.row.answerseconds | getTimeFormat }}
            </template>
          </el-table-column>
          <el-table-column prop="answerseconds_avg" width="120" label="平均通话时长" align="center" class-name="callIn__column-header">
            <template #default="scope">
              {{ Math.round(scope.row.answerseconds_avg) | getTimeFormat }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="呼出统计" align="center" class-name="callOut__column-header">
          <el-table-column prop="call_out_count" width="80" label="话务量" align="center" class-name="callOut__column-header"></el-table-column>
          <el-table-column prop="call_out_answer_count" width="80" label="接听数" align="center" class-name="callOut__column-header"></el-table-column>
          <el-table-column prop="call_out_answer_percent" width="80" label="接听率" align="center" class-name="callOut__column-header">
            <template #default="scope">
              <div :style="`background-color:${getWaringClass(scope.row.call_out_answer_percent)};padding:10px 0 !important;`">
                {{ scope.row.call_out_answer_percent | getPercent }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="call_out_no_answer_lost_count" width="80" label="呼损数" align="center" class-name="callOut__column-header"></el-table-column>
          <el-table-column prop="call_out_no_answer_lost_percent" width="80" label="呼损率" align="center" class-name="callOut__column-header">
            <template #default="scope">
              <div :style="`background-color:${getWaringClass(scope.row.call_out_no_answer_lost_percent)};padding:10px 0 !important;`">
                {{ scope.row.call_out_no_answer_lost_percent | getPercent }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <!--        <el-table-column prop="ringseconds_total" width="120" label="平均响铃时长" align="center">-->
        <!--          <template #default="scope">-->
        <!--            {{ scope.row.ringseconds_total | formattedTime }}-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </dc-datatable>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { tansParams } from "@/api/device/device";
import { getToken } from "@/utils/auth";

export default {
  name: "CallRecordCount",
  filters: {
    getTimeFormat(val) {
      let h = parseInt(val / 3600);
      h = h > 9 ? `${h}` : `0${h}`;
      let m = parseInt((val - 3600 * h) / 60);
      m = m > 9 ? `${m}` : `0${m}`;
      let s = val - 3600 * h - 60 * m;
      s = Math.round(s * 100) / 100;
      s = s > 9 ? `${s}` : `0${s}`;
      return `${h}:${m}:${s}`;
    },
    getPercent(val) {
      return Math.round(val * 10000) / 100 + "%";
    },
  },
  props: {},
  data() {
    return {
      apiUrl: `${this.baseUrls.callDataCount}/callRecordReport`,
      params: {},
      queryParams: {},
      selectList: [
        { label: "总话务量", value: "call_total" },
        { label: "呼入话务量", value: "call_in_count" },
        { label: "呼入接听数", value: "call_in_answer_count" },
        { label: "呼入接听率", value: "call_in_answer_percent" },
        { label: "15s内接听数", value: "answer15" },
        { label: "15s内接听率", value: "answer15_percent" },
        { label: "呼入呼损数", value: "call_in_answer_lost_count" },
        { label: "呼入呼损率", value: "call_in_answer_lost_percent" },
        { label: "呼入总通话时长", value: "answerseconds" },
        { label: "呼入平均通话时长", value: "answerseconds_avg" },
        { label: "呼出话务量", value: "call_out_count" },
        { label: "呼出接听数", value: "call_out_answer_count" },
        { label: "呼出接听率", value: "call_out_answer_percent" },
        { label: "呼出呼损数", value: "call_out_no_answer_lost_count" },
        { label: "呼出呼损率", value: "call_out_no_answer_lost_percent" },
      ],
    };
  },
  created() {
    this.resetPara();
  },
  methods: {
    colunmStyle({ row, column, rowIndex, columnIndex }) {
      //第一列背景色
      if (columnIndex == 3 || columnIndex == 4 || columnIndex == 5 || columnIndex == 6 || columnIndex == 7 || columnIndex == 8 || columnIndex == 9 || columnIndex == 10 || columnIndex == 11) {
        return "background:#96c06b;color:#000;";
      } else if (columnIndex == 12 || columnIndex == 13 || columnIndex == 14 || columnIndex == 15 || columnIndex == 16) {
        return "background:rgb(82 124 231);color:#000;";
      } else {
        return "";
      }
    },
    loaded(data, cb) {
      let _this = this;
      this.$nextTick(async () => {
        let url = `${_this.baseUrls.callDataCount}/callRecordReportTotal`;
        let params = _this.queryParams;
        _this.topsAjax.get(url, params).then(async (resp) => {
          await this.showSummariesPosition(resp);
        });
      });
      // let list = data;
      // list.forEach((p) => {
      // p.call_total = p.call_answer_total + p.call_no_answer_total;
      // p.call_in_answer_percent = p.call_in_count !== 0 ? Math.round((p.call_in_answer_count / p.call_in_count) * 10000) / 100 + "%" : "0%";
      // let call_in_answer_lost_count = p.call_in_count - p.call_in_answer_count;
      // p.call_in_answer_lost_count = call_in_answer_lost_count;
      // p.call_in_answer_lost_percent = p.call_in_count !== 0 ? Math.round((call_in_answer_lost_count / p.call_in_count) * 10000) / 100 + "%" : "0%";
      // p.call_out_answer_percent = p.call_out_count !== 0 ? Math.round((p.call_out_answer_count / p.call_out_count) * 10000) / 100 + "%" : "0%";
      // let call_out_no_answer_lost_count = p.call_out_count - p.call_out_answer_count;
      // p.call_out_no_answer_lost_count = call_out_no_answer_lost_count;
      // p.call_out_no_answer_lost_percent = p.call_out_count !== 0 ? Math.round((call_out_no_answer_lost_count / p.call_out_count) * 10000) / 100 + "%" : "0%";
      // p.answerseconds_avg = p.call_in_answer_count !== 0 ? Math.round((p.answerseconds / p.call_in_answer_count) * 100) / 100 : 0;
      // p.ringseconds_total = p.call_total !== 0 ? Math.round((p.ringseconds_total / p.call_total) * 100) / 100 : 0;
      // p.answer15_percent = p.call_in_answer_count !== 0 ? Math.round((p.answer15 / p.call_in_answer_count) * 10000) / 100 + "%" : "0%";
      // });
      cb(data);
    },
    // 合计行设置
    showSummariesPosition(val) {
      let _this = this;
      this.$nextTick(() => {
        let table = document.querySelector(".el-table");
        let footer = document.querySelector(".el-table__footer-wrapper");
        let body = document.querySelector(".el-table__body-wrapper");

        if (table && footer && body) {
          // 操作主表格的合计行
          table.removeChild(footer);
          table.insertBefore(footer, body);
          footer.style.backgroundColor = "white";

          // 设置主表格的合计行内容
          let cells = table.querySelectorAll("td");
          if (cells.length > 0) {
            let html = cells[0].querySelector(".cell");
            if (html) {
              html.innerHTML = "合计";
            }
            // 继续设置其他单元格的内容
            cells[2].querySelector(".cell").innerHTML = val.call_in_total + val.call_out_total;
            cells[3].querySelector(".cell").innerHTML = val.call_in_total;
            cells[4].querySelector(".cell").innerHTML = val.call_in_answer_total;
            cells[6].querySelector(".cell").innerHTML = val.answer15_total;
            cells[8].querySelector(".cell").innerHTML = val.call_in_total - val.call_in_answer_total;

            const answerseconds_total = this.$options.filters.getTimeFormat(val.answerseconds_total);
            cells[10].querySelector(".cell").innerHTML = answerseconds_total;

            let answerseconds_total_avg_temp = val.call_in_answer_total === 0 ? 0 : Math.round(val.answerseconds_total / val.call_in_answer_total);
            const answerseconds_total_avg = this.$options.filters.getTimeFormat(answerseconds_total_avg_temp);
            cells[11].querySelector(".cell").innerHTML = answerseconds_total_avg;

            cells[12].querySelector(".cell").innerHTML = val.call_out_total;
            cells[13].querySelector(".cell").innerHTML = val.call_out_answer_total;
            cells[15].querySelector(".cell").innerHTML = val.call_out_total - val.call_out_answer_total;
          }

          // 操作固定列的合计行
          // let fixedFooter = document.querySelector(".el-table__fixed .el-table__footer-wrapper");
          // if (fixedFooter) {
          //   fixedFooter.querySelectorAll("tr").forEach((row) => {
          //     row.style.height = footer.offsetHeight + "px"; // 确保高度一致
          //   });
          // }
          // this.$nextTick(() => {
          //   // doLayout() 是一个方法，可以用来手动触发 el-table 的重新布局。这个方法通常在你动态改变了表格的尺寸或者其容器的尺寸之后使用，以确保表格能够正确地重新布局并避免错位。
          //   _this.$refs.list.doLayout();
          // });
        }
      });
    },
    doSearch() {
      this.$refs.list.load();
    },
    getMonth() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().startOf("month").format("YYYY-MM-DD");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().endOf("month").format("YYYY-MM-DD");
      this.doSearch();
    },
    getNowDay() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().format("YYYY-MM-DD");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    reset() {
      this.resetPara();
      this.$nextTick(() => this.doSearch());
    },
    resetPara() {
      this.queryParams = {
        username: "",
        detail: "",
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        orderType: "desc",
        orderBy: this.selectList[0].value,
      };
    },
    exportExcel() {
      let url = `${this.baseUrls.callDataCount}/callRecordReportExport`;
      let params = this.queryParams;
      axios
        .post(url, params, {
          transformRequest: [
            (params) => {
              return tansParams(params);
            },
          ],
          headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: getToken() },
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "话务量报表统计.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
    getWaringClass(val) {
      if (val < 0.2) {
        return "#ff0000";
      } else if (val < 0.4) {
        return "#ad1f1f";
      } else if (val < 0.6) {
        return "#d77272";
      } else if (val < 0.8) {
        return "#ff9100";
      } else {
        return "#96c06b";
      }
    },
  },
};
</script>
<style lang="scss">
.callRecordCountReportContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;

  overflow: hidden;

  .el-date-editor {
    width: 170px;
  }

  &__top {
    background-color: white;

    .title {
      font-size: 18px;
    }

    padding: 10px;

    .el-divider {
      margin: 12px 0;
    }
  }

  .buttons {
    text-align: right;
    clear: both;
    padding-right: 10px;
    background-color: white;
  }

  &__body {
    flex: 1;
    border-top: none;
    padding: 10px;
    background-color: white;
  }

  .custom-table-container {
    position: relative;
    overflow: hidden;
  }

  .fixed-column {
    position: sticky;
    left: 0;
    z-index: 10;
    background-color: #fff;
    //box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .el-table {
    display: flex;
    flex-direction: column;
  }
  .el-table__body-wrapper {
    order: 1;
  }
  .el-table__fixed-body-wrapper {
    top: 96px !important;
  }
  .el-table__fixed-footer-wrapper {
    z-index: 0;
  }
  .el-table__footer-wrapper {
    border-bottom: 1px solid #ebeef5 !important;
  }

  .callIn__column-header {
    background: #96c06b !important;
    color: #000 !important;
    padding: 0px !important;
    .cell {
      //padding: 10px 0 !important;
    }
  }
  .callOut__column-header {
    background: rgb(82 124 231) !important;
    color: #000 !important;
    padding: 0px !important;
  }
  .percent_red {
    padding: 10px 0 !important;
    background-color: red;
  }
  th > .cell {
    padding: 10px 0 !important;
  }
}
</style>
